import { CloseIcon } from '@c/icons';
import { cva, VariantProps } from 'class-variance-authority';
import Link from 'next/link';

const styles = cva(
  ' flex rounded-lg text-brand-primary font-inherit justify-center items-center px-[1.6rem] py-[0.2rem] whitespace-nowrap gap-[0.8rem] font-inherit',
  {
    variants: {
      color: {
        primary: 'bg-brand-lightest-gray text-brand-primary',
        gray: 'bg-gray-200 text-inherit',
        success: 'bg-green-100 text-brand-green',
        warning: 'bg-yellow-100 text-yellow-500',
        error: 'bg-red-100 text-brand-red',
        blue: 'bg-blue-100 text-brand-blue',
        active:
          'bg-brand-white border-[1px] border-brand-secondary text-brand-secondary  ',
        pending:
          'bg-brand-primary-lighter text-brand-primary text-[1.2rem] font-medium',
      },
      fontWeight: {
        normal: 'font-normal',
        medium: 'font-medium',
        semiBold: 'font-semibold',
        bold: 'font-bold',
      },
    },
    defaultVariants: {
      color: 'primary',
      fontWeight: 'normal',
    },
  }
);
interface ChipProps extends VariantProps<typeof styles> {
  text: string;
  isDismissible?: boolean;
  onDismissClicked?: () => void;
  href?: string;
  color:
    | 'gray'
    | 'primary'
    | 'success'
    | 'warning'
    | 'error'
    | 'active'
    | 'pending'
    | 'blue';
  fontWeight?: 'normal' | 'medium' | 'semiBold' | 'bold';
  leadingIcon?: React.ReactNode;
}

const Chip = ({
  text,
  isDismissible,
  onDismissClicked,
  color,
  fontWeight = 'normal',
  href,
  leadingIcon,
}: ChipProps) => {
  return href ? (
    <Link href={href} className={styles({ color, fontWeight })}>
      {text}
    </Link>
  ) : (
    <div className={styles({ color })}>
      {leadingIcon}
      <span>{text}</span>
      {isDismissible && (
        <button
          onClick={() => {
            onDismissClicked?.();
          }}
        >
          <CloseIcon height={16} width={16} />
        </button>
      )}
    </div>
  );
};

export default Chip;
