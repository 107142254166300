import BaseDrawer from '@c/drawers/BaseDrawer';
import { CartIcon } from '@c/icons';
import CloseButton from '@c/icons/buttons/CloseButton';
import Button from '@ui/Button';
import { CartItemDisplay } from '@ui/CartItemDisplay';
import { logInitCheckout } from '@util/analytics';
import { formatCurrency } from '@util/index';
import MxReturnOption from 'app/my-cart/components/mx-return-option';
import { useAuth } from 'context/AuthContext';
import { useShoppingCart } from 'context/ShoppingCartContext';

interface ShoppingCartProps {}

const ShoppingCart = ({}: ShoppingCartProps) => {
  const { userDoc } = useAuth();
  const { cartOpen, setCartOpen, cart, realTimeProducts } = useShoppingCart();
  if (!cart) return null;

  const getCartItem = (productId: string) =>
    cart.items.find((i) => i.product_id === productId);

  return (
    <BaseDrawer
      show={cartOpen}
      side={'right'}
      dismiss={() => setCartOpen(false)}
    >
      {/* fixed z-40 h-screen right-0 */}
      <div className="flex h-full w-full flex-col gap-[2.4rem] divide-y overflow-y-auto overflow-x-clip bg-brand-white font-semibold text-brand-black sm:pb-[17rem]">
        <div className="flex justify-between px-[1.6rem] pt-[2.4rem] xl:px-[2.4rem]">
          <h5 className="font-semibold">Shopping Cart</h5>
          <CloseButton onClick={() => setCartOpen((prev) => !prev)} />
        </div>
        {/* empty state */}
        {!cart.items.length && (
          <div className="mt-auto flex h-full flex-col items-center justify-center">
            <div className="flex flex-col items-center gap-[3.2rem]">
              <div className="text-brand-lighter-gray">
                <CartIcon width={64} height={64} />
              </div>
              <div className="flex flex-col items-center gap-[0.8rem] p-[0.8rem]">
                <p>No Items</p>
                <p className="text-center font-normal">You cart is empty.</p>
              </div>
              <Button
                onClick={() => setCartOpen((prev) => !prev)}
                href="/shop"
                text="Continue Shopping"
                type="secondary"
              />
            </div>
          </div>
        )}
        {/* not empty state */}
        {!!cart.items.length && (
          <>
            {/* items */}
            <div className="pt-[2.4rem]">
              <div className="px-[1.6rem] xl:px-[2.4rem]">
                {realTimeProducts.map((p) => (
                  <CartItemDisplay
                    key={p.id}
                    product={p}
                    cartItem={getCartItem(p.id)}
                  />
                ))}
              </div>
            </div>

            {/* recommendations
            {cart.items.length && (
              <div className="order-2 px-[1.6rem] pt-[2.4rem] xl:px-[2.4rem]">
                <RecDisplay />
              </div>
            )} */}

            {/* total/checkout */}
            <div className="inset-x-0 bottom-0 z-50 bg-brand-white pt-[2.4rem] sm:fixed sm:mt-auto">
              <div className="flex flex-col gap-[2rem] px-[2.4rem] pb-[2.4rem]">
                <div className="flex items-center justify-between">
                  <p className="text-[1.5rem]">Total (USD)</p>
                  <p className="text-[2.4rem]">
                    {formatCurrency(cart.subtotal + (cart.ra_fee ?? 0))}
                  </p>
                </div>
                <MxReturnOption />

                <Button
                  text="View cart"
                  href="/my-cart"
                  height="small"
                  type="secondary"
                  onClick={() => {
                    setCartOpen((prev) => !prev);
                    // logInitCheckout(
                    //   realTimeProducts,
                    //   cart.subtotal,
                    //   userDoc?.uid ?? ''
                    // );
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </BaseDrawer>
  );
};

export default ShoppingCart;
